export function toggleMenu() {
  const hamburger = document.querySelector('#hamburger');
  const mobileMenu = document.querySelector('#mobileMenu');
  if (hamburger != null) {
    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('open');
      handleMenu(mobileMenu);
    });
  }

  const parentNavItems = mobileMenu.querySelectorAll('.menu-item-has-children');
  parentNavItems.forEach((item) => {
    item.querySelector('a').addEventListener('click', (e) => {
      e.preventDefault();
      handleSubmenu(item, parentNavItems);
    });
  });
}

const openMenu = (menu) => {
  const content = menu.querySelector(".menu__content");
  setTimeout(() => {
    menu.classList.add("active");
    content.style.maxHeight = content.scrollHeight + "px";
  }, 10);
  setTimeout(() => {
    content.style.maxHeight = null;
  }, 310);
};

const closeMenu = (menu) => {
  const content = menu.querySelector(".menu__content");
  content.style.maxHeight = content.scrollHeight + "px";
  setTimeout(() => {
    menu.classList.remove("active");
    content.style.maxHeight = "0px";
  }, 10);
};

const handleMenu = (menu) => {
  const content = menu.querySelector(".menu__content");

  if (content.style.maxHeight != "0px") {
    closeMenu(menu);
    const parentNavItems = menu.querySelectorAll('.menu-item-has-children');
    parentNavItems.forEach((item) => {
      const itemSubmenu = item.querySelector(".menu__content");
      if (itemSubmenu.style.maxHeight != "0px") {
        closeMenu(item);
      }
    });
  } else {
    openMenu(menu);
  }
};

const handleSubmenu = (submenu, items) => {
  const content = submenu.querySelector(".menu__content");

  items.forEach((item) => {
    const itemSubmenu = item.querySelector(".menu__content");
    if (itemSubmenu.style.maxHeight != "0px") {
      closeMenu(item);
    }
  });

  if (content.style.maxHeight != "0px") {
    closeMenu(submenu);
  } else {
    openMenu(submenu);
  }
}