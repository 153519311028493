import { toggleMenu } from "./header";
import { scrollToHash, getSamePageAnchor } from "./smooth-scrolling";
import '../css/main.css';


document.addEventListener("DOMContentLoaded", () => {
  /**
   * Toggle del header
   */
  toggleMenu()

  /**
   * Example carousel import
   */
  const carousel = document.querySelectorAll(".carousel");
  if (carousel.length > 0) {
    import("./carousel").then((module) => {
      carousel.forEach((slider) => {
        module.carousel(slider);
      });
    });
  }

  /**
   * Smooth scrolling support
   */
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
      if (a.dataset.noscroll === undefined) {
        scrollToHash(getSamePageAnchor(a), e);
      }
    });
  });
  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);

  /**
   * ACF7 event listener
   */
  document.addEventListener('wpcf7mailsent', (event) => {
    gtag('event', 'Formulario', {
      'event_category': event.detail.contactFormId,
      'event_label': event.detail.unitTag
    });
  }, false);

  const accordions = document.querySelectorAll(".accordion");
  if (accordions.length > 0) {
    import("./accordions").then((module) => {
      accordions.forEach((accordion) => {
        module.handleAccordion(accordion);
      });
    });
  }

  const formCheckboxes = document.querySelectorAll("#formCheckboxes input");
  const forms = document.querySelectorAll("#forms > div");
  if (formCheckboxes.length > 0 && forms.length > 0) {
    formCheckboxes.forEach((checkbox, index) => {
      checkbox.addEventListener("change", (event) => {
        const form = forms[index];
        forms.forEach((form, index2) => {
          form.style.display = "none";
          if (index === index2) {
            form.style.display = "block";
          }
        });
      });
    });
  }

  document.addEventListener('wpcf7mailsent', function (event) {
    if (event.detail.status == 'mail_sent') {
      document.querySelector('#contact-form-modal').style.display = 'block';
    }
  });

  window.addEventListener('scroll', function (e) {
    let scrollTop = window.pageYOffset;
    let fixedElement = document.querySelector("#main-menu");
    const headerTop = document.querySelector("#header-top");
    const headerTopHeight = headerTop.clientHeight;

    if (scrollTop > headerTopHeight) {
      fixedElement.classList.add("fixed");
      fixedElement.classList.remove("relative");
      fixedElement.style.top = '0px';
      fixedElement.style.width = `100%`;
    } else {
      fixedElement.classList.remove("fixed");
      fixedElement.classList.add("relative");
    }
    if (scrollTop > 0) {
      fixedElement.classList.add("bg-primary");
    } else {
      fixedElement.classList.remove("bg-primary");
    }
  });

  const toggleRepresentatives = document.querySelectorAll(".container-consejo ul.areas li a");
  if (toggleRepresentatives.length > 0) {
    const members = document.querySelectorAll(".integrantes");
    toggleRepresentatives.forEach((toggle) => {
      toggle.addEventListener("click", (e) => {
        e.preventDefault();
        var hash = toggle.href.split('#')[1];
        toggleRepresentatives.forEach((elem) => elem.style.fontWeight = "normal");
        toggle.style.fontWeight = "bold"
        members.forEach((member) => {
          if (member.id === hash) {
            member.style.display = "block";
          } else {
            member.style.display = "none";
          }
        });
      });
    });
  }

  const queryConveniosForm = document.querySelector("#queryConveniosForm");
  if (queryConveniosForm) {
    import("./form-query").then((module) => {
      module.queryPosts(queryConveniosForm);
    });
  }
});



